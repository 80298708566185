import React, { useState, useEffect } from "react";
import { NavLink ,BrowserRouter , Router, Routes ,Route , useNavigate , Link} from "react-router-dom";
import './Admin.css';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import admin_img from '../Admin/admin.png';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import userprofile from '../Admin/admin.png';

import ReactECharts from 'echarts-for-react';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';


// products image
// product images
import goodDay from '../../image/products/goodday.webp'
import milkbikis from '../../image/products/milkbikis.jpg'
import mariegold from '../../image/products/marie_gold.webp';
import hide_and_seek from '../../image/products/hide_seek.webp';
import parle_g from '../../image/products/parle_g.webp';
import dark_fantasy from '../../image/products/dark_fantasy.webp';
import treat from '../../image/products/treat.png';
import krackjack from '../../image/products/krackjack.jpg';
import bourbon from '../../image/products/bourbon.jpg';
import oreo from '../../image/products/oreo.webp';



const Admin = ()=>{



   const image_json = [
    {"Milk Bikis":milkbikis},
    {"Marie Gold":mariegold},
    {"Hide & Seek":hide_and_seek},
    {"Parle G":parle_g},
    {"Dark Fantasy":dark_fantasy},
    {"Good Day":goodDay},
    {"Treat":treat},
    {"Krackjack":krackjack},
    {"Bourbon":bourbon},
    {"Oreo":oreo}
   ];

   const handle_image_Src = (product_name)=>{
    const imageObject = image_json.find((item) => item[product_name]);
    return imageObject[product_name];
  };


    const totday_stock = 100;
    // onscroll usestate based on navbar color change
    const [nav_bg_color,setNavbgcolor] = useState('transparent');

    // onscroll time navbar color change function
    const handlescroll = () =>{
        const scrollY = window.screenY;
        if (scrollY>1) {
            setNavbgcolor('#edf2f9f5')
        }else{
            setNavbgcolor('#edf2f9f5')
        }
    }

    const navigate = useNavigate();
    // logout function
    const handle_logout = ()=>{
        navigate('/login');
    }

    const [hourly_cost_opt,setHourly_Cost_opt] = useState({});
    const [hourly_order_opt,setHourly_Order_opt] = useState({});
    const [weekly_sales_opt,setWeeklySales_opt] = useState({});
    const [today_sales_count,setTodaysales] = useState(0);
    const [today_sales_amount,setTodayAmount] = useState(0);
    const [product_arr,setProductArr] = useState([]);
    const [today_percentage,setTodayPercentage] = useState([]);
    const hourly_sales = async()=>{
        let hours_Arr = [];
        let hours_amount = [];
        let hours_order = [];

        let weeks_arr_name = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
        let weeks_sales_arr = [];
        // const response = await fetch('http://localhost:3003/session/', { method: 'GET',  headers: { 'Content-Type': 'application/json' } });
        const response = await fetch('https://smart-trolley-api.quantanics.in/session/', { method: 'GET',  headers: { 'Content-Type': 'application/json' } });
        const orderData = await response.json();
        console.log("order back-end data");
        console.log(orderData.hourly_data);
        let total_amount = 0;
        let total_order_count = 0;
        let today_total_order = 0;
        orderData.hourly_data.map((item,index)=>{
            total_amount+=item.amount;
            total_order_count+=item.order_count;
            hours_Arr.push(item.hour);
            hours_amount.push(item.amount);
            hours_order.push(item.order_count);
            today_total_order+=item.order_count;
        });
        let percentage_tmp = (today_total_order/totday_stock)*100;
        setTodayPercentage(parseFloat(percentage_tmp).toFixed(1));
        setTodayAmount(total_amount);
        setTodaysales(total_order_count);

        
        console.log(orderData.current_week_dates);
        let total_amount_week = 0;
        
        orderData.current_week_dates.map((item,index)=>{
            weeks_sales_arr.push(item.amount);
            total_amount_week+=item.amount;
        });
        setWeekrevenue(total_amount_week);

        setHourly_Cost_opt({
            tooltip: {
                trigger: 'axis',
                formatter: function (params) {
                    const hour = params[0].axisValue; // Get hour from x-axis
                    const value = params[0].data; // Get sales value
                    return `Hour: ${hour}<br/>Sales: ${value}`; // Tooltip content
                },
            },
            xAxis: {
                type: 'category',
                data: hours_Arr, // Define hours here
                show: false,
            },
            yAxis: {
                type: 'value',
                show: false,
            },
            series: [
                {
                    name: 'Sales',
                    type: 'bar',
                    data: hours_amount,
                    lineStyle: {
                        width: 3,
                    },
                },
            ],
        })

        setHourly_Order_opt({
        
            tooltip: {
                trigger: 'axis',
                formatter: function (params) {
                    const hour = params[0].axisValue; // Get hour from x-axis
                    const value = params[0].data; // Get sales value
                    return `Hour: ${hour}<br/>Orders: ${value}`; // Tooltip content
                },
            },
            xAxis: {
              type: 'category',
              data: hours_Arr,
              show:false,
            },
            yAxis: {
              type: 'value',
              show:false,
              max: Math.max(...hours_order) + 10,
            },
            series: [
              {
                name: 'Sales',
                type: 'line',
                smooth: true,  // Makes the line smooth
                data: hours_order,
                lineStyle: {
                  width: 3,
                },
                // Gradient for the line
                itemStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      { offset: 0, color: '#ff7f50' }, // Start color (orange)
                      { offset: 1, color: '#87cefa' }, // End color (light blue)
                    ],
                  },
                },
                areaStyle: {
                  // Gradient under the line (optional)
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      { offset: 0, color: 'rgba(255, 127, 80, 0.5)' },  // Top transparent orange
                      { offset: 1, color: 'rgba(135, 206, 250, 0.1)' }, // Bottom transparent light blue
                    ],
                  },
                },
              },
            ],
        });

        setWeeklySales_opt({
            tooltip: {
                trigger: 'axis',
                formatter: function (params) {
                    const hour = params[0].axisValue; // Get hour from x-axis
                    const value = params[0].data; // Get sales value
                    return `Hour: ${hour}<br/>Sales: ${value}`; // Tooltip content
                },
            },
            xAxis: {
                type: 'category',
                data: weeks_arr_name, // Define hours here
                show: false,
            },
            yAxis: {
                type: 'value',
                show: false,
            },
            series: [
                {
                    name: 'Sales',
                    type: 'bar',
                    data: weeks_sales_arr,
                    lineStyle: {
                        width: 3,
                    },
                },
            ],
        })

        let product_arr_tmp = [];
        
        console.log("order data");
        console.log(orderData);
        orderData.product_arr.map((item,index)=>{
            let obj_tmp = {};
            obj_tmp['percentage'] = parseInt((item.count/item.stock)*100);
            obj_tmp['first_letter'] = item.product_name.charAt(0);
            obj_tmp['product_name'] = item.product_name;
            obj_tmp['revenue'] = (item.count*item.product_price);
            product_arr_tmp.push(obj_tmp);
        });

        console.log("runing products");
        console.log(product_arr_tmp);
        setProductArr(product_arr_tmp);

    }

    const [total_salres_graph,setTotalSales_graph] = useState({});
    const [active_user_Arr,setActiveUserArr] = useState([]);
    const [week_revenue,setWeekrevenue] = useState(0);
    const [product_sales_opt,setProductSales] = useState({});
    const [product_vs_sales,setPie_product_sales] = useState({});
    const [total_revenue,setTotalRevenue] = useState(0);
    const [total_revenue_percentage,setRevenue_percentage] = useState(0);
    const total_Sales = async()=>{
        // const response = await fetch('http://localhost:3003/session/', { method: 'GET',  headers: { 'Content-Type': 'application/json' } });
        const response = await fetch('https://smart-trolley-api.quantanics.in/session/', { method: 'GET',  headers: { 'Content-Type': 'application/json' } });
        const result = await response.json();

        let product_name_arr = [];
        let product_amount_Arr = [];
       
        let product_sales_tmp = [];
        let total_stock_count = 0;
        let total_sales_count = 0;
        let total_amount_revenue = 0;
        result.product_arr.map((item,index)=>{
            product_name_arr.push(item.product_name);
            product_amount_Arr.push(item.count*item.product_price);
            let tmp_obj = {};
            tmp_obj['product'] = item.product_name;
            tmp_obj['stock'] = item.stock;
            tmp_obj['sales'] = item.count;
            product_sales_tmp.push(tmp_obj);
            total_stock_count+=item.stock;
            total_sales_count+=item.count;

        });

        let tmp_revenue = product_amount_Arr.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

        setTotalRevenue(tmp_revenue);


        let sales_percentage = (total_sales_count/total_stock_count)*100;
        setRevenue_percentage(sales_percentage);
        setPie_product_sales({
            tooltip: {
                trigger: 'item',  // Tooltip activates on each pie slice
                formatter: '{b}: {c} ({d}%)'  // Display name, value, and percentage
            },
            series: [
              {
                type: 'pie',
                label: {
                    show: false
                },
                data: [
                  {
                    value: total_stock_count,
                    name: 'Stock'
                  },
                  {
                    value: total_sales_count,
                    name: 'Sales'
                  }
                ]
              }
            ]
        })



        // product vs sales graph
        setProductSales({
            legend: {},
            tooltip: {
                trigger: 'axis', // Show tooltip for all series at each x-axis point
                axisPointer: {
                    type: 'shadow' // Display a shadow under bars when hovered
                },
                formatter: function (params) {
                    // Assuming params is an array with both 'stock' and 'sales' values for each product
                    const product = params[0].axisValue;
                    const stock = params[0].data['stock'];
                    const sales = params[1].data['sales'];
                    return `
                        <strong>Product:</strong> ${product}<br/>
                        <strong>Stock:</strong> ${stock}<br/>
                        <strong>Sales:</strong> ${sales}
                    `;
                }
            },
            dataset: {
                dimensions: ['product','stock', 'sales'],
                source: product_sales_tmp
            },
            xAxis: { type: 'category' },
            yAxis: {},
            // Declare several bar series, each will be mapped
            // to a column of dataset.source by default.
            series: [{ type: 'bar',name:'stock' }, { type: 'bar',name:'sales' }]
        });




       
        let active_users_arr = [];
        result.user_Details.map((item,index)=>{
            let user_obj = {};
            user_obj['username'] = item.name;
            user_obj['visiting_count'] = item.count;
            active_users_arr.push(user_obj);
        });

        setActiveUserArr(active_users_arr);

        setTotalSales_graph({
        
            tooltip: {
              trigger: 'axis',
            },
            xAxis: {
              type: 'category',
              data: product_name_arr,
              show:true,
            },
            yAxis: {
              type: 'value',
              show:true,
            },
            series: [
              {
                name: 'Sales',
                type: 'line',
                smooth: true,  // Makes the line smooth
                data: product_amount_Arr,
                lineStyle: {
                  width: 3,
                },
              },
            ],
        })


    }

   


    // useeffect its once the browser was scrolle down the event call the function
    useEffect(()=>{
        window.addEventListener('scroll',handlescroll);

        hourly_sales();
        total_Sales();
       

        return ()=>{
            window.removeEventListener('scroll',handlescroll);
        }
    },[])



    return(
        <>
            <nav className='nav_main_class' style={{backgroundColor:nav_bg_color}}>
                <div className='nav_icon_subdiv'>
                    <div className='menu_icon_div'  ><MenuIcon  className='menu_icon' /></div>
                    <div className='logo_subdiv_content' >
                        <img src="https://upload.wikimedia.org/wikipedia/en/thumb/b/b0/Sethu_Institute_of_Technology_Logo.png/220px-Sethu_Institute_of_Technology_Logo.png" alt="" style={{width:'2.5rem'}}/>
                        <h6 className='nav_company_name' >Smart Trolley</h6>
                    </div>
                </div>
                <div className='nav_subdiv_btn'>
                <div className="logout_icon" style={{color:'gray',opacity:0.6}}><NotificationsIcon className="nav_icon_size" /></div>
                    <div className="logout_icon" style={{color:'gray',opacity:0.6}}><ShoppingCartIcon className="nav_icon_size" /></div>
                    <div className='img_circle_div'>
                        <img className='img_nav' src={admin_img} alt="" />
                    </div>
                    <div className="logout_icon" ><LogoutIcon className="nav_icon_size" onClick={handle_logout}/></div>
                </div>
            </nav>


            <section className='base_container'>
                <div className="graph_card_div" >
                    <div className="card_outerline_div" >
                        <div className="card_grph" >
                            <div className="card_graph_title_div" >
                                <h6 className="card_graph_title" >Today Sales <HelpOutlineIcon className="card_graph_icon" /></h6>
                            </div>
                            <div className="card_inner_graph" >
                                <div className="card_graph_value" >
                                    <p className="card_graph_num_val" ><CurrencyRupeeIcon style={{fontSize:'1.5rem'}}/>{today_sales_amount}</p>
                                    <span className="card_graph_per_val" >+{today_percentage}%</span>
                                </div>
                                <div className='card_graph_base_div' style={{height:'4rem',width:'50%'}}>
                                    <ReactECharts option={hourly_cost_opt} style={{ height: '100%', width: '100%' }} />
                                </div>     
                            </div>
                        </div>
                    </div>
                    <div className="card_outerline_div" >
                        <div className="card_grph">
                            <div className="card_graph_title_div" >
                                <h6 className="card_graph_title">Today Order <HelpOutlineIcon className="card_graph_icon"/></h6>
                            </div>
                            <div className="card_inner_graph">
                                <div className="card_graph_value">
                                    <p className="card_graph_num_val"><ChildFriendlyIcon />{today_sales_count}</p>
                                    <span className="card_graph_per_val">+{today_percentage}%</span>
                                </div>
                                <div className='card_graph_base_div' style={{height:'4rem',width:'50%'}}>
                                    <ReactECharts option={hourly_order_opt} style={{ height: '100%', width: '100%' }} />
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>

                <div className="graph_card_div">
                    <div className="card_outerline_div">
                        <div className="card_grph">
                            <div className="card_graph_title_div">
                                <h6 className="card_graph_title">Product vs Sales <HelpOutlineIcon className="card_graph_icon"/></h6>
                            </div>
                            <div className="card_inner_graph">
                                <div className="card_graph_value" >
                                    <p className="card_graph_num_val"><CurrencyRupeeIcon style={{fontSize:'1.5rem'}}/>{total_revenue}</p>
                                    <span className="card_graph_per_val">+{parseFloat(total_revenue_percentage).toFixed(2)}%</span>
                                </div>
                                <div className='card_graph_base_div' style={{height:'4rem',width:'50%'}}>
                                    <ReactECharts option={product_vs_sales} style={{ height: '100%', width: '100%' }} />
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="card_outerline_div">
                        <div  className="card_grph">
                            <div className="card_graph_title_div">
                                <h6 className="card_graph_title">Weekly Sales <HelpOutlineIcon style={{fontSize:'1rem',color:'gray',opacity:0.4,marginLeft:'0.3rem'}}/></h6>
                            </div>
                            <div className="card_inner_graph">
                                <div className="card_graph_value">
                                    <p className="card_graph_num_val"><CurrencyRupeeIcon style={{fontSize:'1.5rem'}}/>{week_revenue}</p>
                                    <span className="card_graph_per_val">+{parseFloat(total_revenue_percentage).toFixed(2)}%</span>
                                </div>
                                <div className='card_graph_base_div' style={{height:'4rem',width:'50%'}}>
                                    <ReactECharts option={weekly_sales_opt} style={{ height: '100%', width: '100%' }} />
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>


                {/* runing products */}
                <div className="runing_product_base_div" >
                    <div className="runing_product_outer_div" >
                        <div className="card_grph">
                            <div className="card_graph_title_div">
                                <h6 className="card_graph_title">Runing Porducts </h6>
                            </div>
                            {/* new css occured */}
                            <div className="runing_product_overflow_div" >
                                {console.log("runing products123",product_arr)}
                                {product_arr.map((item,index)=>(
                                   
                                    <div key={index} className='runing_product_div' >
                                        <div className='runing_product_profile_dive' >
                                            <div className='runing_product_profile_circle' >
                                                <span className='runing_product_profile_letter' >{item.first_letter}</span>
                                            </div>
                                        </div>
                                        <div className="runing_product_details_div" >
                                            <div className='runing_product_np'>
                                                <span className='runing_product_name' >{item.product_name}</span>
                                                <span className='runing_product_percentage' >{item.percentage}%</span>
                                            </div>
                                            <div className='runing_product_progress_div' >
                                                <LinearProgress variant="determinate" value={item.percentage} className="w-100" />
                                            </div>
                                        </div>
                                    </div>
                                   
                                ))}
                                

                               
                            </div>
                           
                        </div>
                    </div>
                    <div className="runing_product_outer_div" >
                        <div className="card_grph" id="" style={{padding:'1rem'}}>
                            <div className="card_graph_title_div" >
                                <h6 className="card_graph_title">Total Sales </h6>
                            </div>
                            <ReactECharts option={total_salres_graph} style={{ height: '100%', width: '100%' }} />
                        </div>
                    </div>
                </div>


                {/* best selling products and active users  */}
                <div className="best_selling_product_div" >
                    <div className="best_selling_product_outer_div" >
                        <div className="card_grph">
                            <div className="best_selling_title_div" >
                                <div className="best_selling_title1" >
                                    <span>Best Selling Products</span>
                                </div>
                                <div className="best_selling_title2">
                                    <div className="best_selling_title21" >Revenue(<CurrencyRupeeIcon style={{fontSize:'1rem'}}/>)</div>
                                    <div className="best_selling_title22" >Usage</div>
                                </div>
                            </div>
                            {/* new css occured */}
                            <div className="best_selling_overflow_div">
                                {product_arr.map((item,index)=>(
                                    <div className="best_selling_product_card_div" >
                                        <div className="best_selling_product_profile" >
                                            <img className="best_selling_product_profile_img" src={handle_image_Src(item.product_name)}  alt="" />
                                            <div className="best_selling_product_profile_title" >
                                                <span className="best_selling_product_profile_title_big" >{item.product_name}</span>
                                                <span className="best_selling_product_profile_title_small" >Seeling</span>
                                            </div>
                                        </div>
                                        <div className="best_selling_product_val" >
                                            <div className="best_selling_product_val_inr" >
                                            <CurrencyRupeeIcon style={{fontSize:'1rem'}}/>{item.revenue}
                                            </div>
                                            <div className="best_selling_product_val_per" >
                                                <div className="best_selling_product_val_progress" >
                                                    <LinearProgress variant="determinate" value={item.percentage} className="w-100" />
                                                </div>
                                                <div className="best_selling_product_val_perf">{item.percentage}%</div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                               
                                
                            </div>
                           
                        </div>
                    </div>
                    <div className="active_users_base_div" >
                        <div className="card_grph">
                            <div  className="card_graph_title_div mrt-1" >
                                <h6  className="card_graph_title">Active Customers </h6>
                            </div>
                            <div className="runing_product_overflow_div" style={{paddingLeft:'1rem'}}>
                                {active_user_Arr.map((item,index)=>(
                                    <div className='active_users_div' >
                                        <div className="active_user_profile_div" >
                                            <img src={userprofile} className="active_user_profile" />
                                        </div>
                                        <div className="active_user_details_div" >
                                            <p  className="active_user_details_name">{item.username}</p>
                                            <small className="active_user_details1" >Visting Count {item.visiting_count}</small>
                                        </div>
                                    </div>
                                ))}
                                
                            </div>
                                                    
                        </div>
                    </div>
                </div>

                {/* products and sales */}
                <div className="product_sales_div" style={{height:'30rem'}}>
                    <div className="card_grph" style={{padding:'1rem'}}>   
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'start',alignItems:'center',padding:'1rem',fontSize:'1rem',fontWeight:'bold',color:'#344050'}}>Product Vs Sales</div>                     
                        <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100%',width:'90%;'}}>
                            <ReactECharts option={product_sales_opt} style={{ height: '100%', width: '100%' }} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export  default  Admin;