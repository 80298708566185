import React, { useContext, useEffect, useState} from "react";
import '../after_items_scan/cart_page.css'
import { RiAddBoxFill } from "react-icons/ri";
import { TbSquareMinusFilled } from "react-icons/tb";
import { TrolleyContext } from "../../TrolleyContext";

import { useNavigate} from 'react-router-dom'
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

// if mqtt publisher need this page use this libraries
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import mqtt from 'mqtt';



// product images
import goodDay from '../../image/products/goodday.webp'
import milkbikis from '../../image/products/milkbikis.jpg'
import mariegold from '../../image/products/marie_gold.webp';
import hide_and_seek from '../../image/products/hide_seek.webp';
import parle_g from '../../image/products/parle_g.webp';
import dark_fantasy from '../../image/products/dark_fantasy.webp';
import treat from '../../image/products/treat.png';
import krackjack from '../../image/products/krackjack.jpg';
import bourbon from '../../image/products/bourbon.jpg';
import oreo from '../../image/products/oreo.webp';



  

const CartPage = () => {
    const {subTotal,setAmount} = useContext(TrolleyContext)
    const {updatedData,setSubtotal,device_id} = useContext(TrolleyContext)
    

    const [products, setProducts] = useState()
    const navigate = useNavigate()

    const taxRate = 0.05


   const image_json = [
    {"Milk Bikis":milkbikis},
    {"Marie Gold":mariegold},
    {"Hide & Seek":hide_and_seek},
    {"Parle G":parle_g},
    {"Dark Fantasy":dark_fantasy},
    {"Good Day":goodDay},
    {"Treat":treat},
    {"Krackjack":krackjack},
    {"Bourbon":bourbon},
    {"Oreo":oreo}
   ];


    const handleAdd = (id) => {
        setProducts(products.map(product =>
            product.serial_number === id ? { ...product, quantity: product.quantity + 1 } : product
        ));
    };

    const handleMinus = (id) => {
        setProducts(products.map(product =>
            product.serial_number === id && product.quantity > 1 ? { ...product, quantity: product.quantity - 1 } : product
        ));
    };

   

    const handleCheckout = () =>{
        // alert('hi');
        navigate('/payment')
    }

    const calculateTax = () => {
        return subTotal * taxRate;
    };

    const calculateTotalPrice = () => {
        const totalamount = subTotal + calculateTax()
        setAmount(totalamount.toFixed(2))
        return totalamount.toFixed(2);
    };

    const [suggest_product_arr,setSuggest_product] = useState([]);
    const suggest_product = async()=>{
        // const response = await fetch('http://localhost:3003/session/suggest_product', { method: 'GET',  headers: { 'Content-Type': 'application/json' } });
        const response = await fetch('https://smart-trolley-api.quantanics.in/session/suggest_product', { method: 'GET',  headers: { 'Content-Type': 'application/json' } });
        const orderData = await response.json();
        let product_Arr = [];
        orderData.product_Arr.map((item,index)=>{
            if(item.count>=orderData.min_max.average_count){
                product_Arr.push(orderData.product_Arr[index]);
                
            }
        });

        setSuggest_product([]);
        setSuggest_product(product_Arr);
        console.log("final product array");
        console.log(product_Arr);

    }

    useEffect(()=>{
        suggest_product();
        if(updatedData.length>0){

            // setSubmitted(true);
            // setresAlert("hi its wokring fine");
            // console.log("updated data");
            // console.log(updatedData);
            setProducts(updatedData?.map(product => ({ ...product, quantity: 1 })))

            // console.log("json imahes");
            // console.log(image_json);

            

        }else{
           navigate('/');
        }
    },[updatedData])

    useEffect(() => {
        if(products?.length > 0){
        }
        const calculateSubtotal = () => {
            return products?.reduce((acc, product) => acc + product.price * product.quantity, 0);
        };
        const newSubtotal = calculateSubtotal();
        setSubtotal(newSubtotal);
        
    }, [products]);


   const handle_image_Src = (product_name)=>{
     const imageObject = image_json.find((item) => item[product_name]);
     return imageObject[product_name];
   };

   const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true
  };
   
const Carousel_Demo = ({ demo_arr })=>(
    <>
        {console.log('Carousel_Demo re-rendered')}
        {demo_arr.length > 0 && (
            <div style={{ width: "90%", margin: "0 auto" }}>
                <Slider {...settings}>
                    {console.log("suggest products")}
                    {console.log(demo_arr)}
                    {demo_arr.map((product, index) => (
                        <div key={index}>
                            <div className="card_box" style={{ backgroundColor: '#c7f5b5', opacity: '0.8' }}>
                                <div className="img_div">
                                    <img alt={`product image ${product.product_name}`} className="card_img" src={handle_image_Src(product.product_name)} />
                                </div>
                                <div className="content_div">
                                    <div className="text_div">
                                        <span className="product_name">{product.product_name}</span>
                                        <span className="product_price">{`Discount Rs: ${product.product_price}`}</span>
                                        <span className="product_desc">Product Availability : {product.stock-product.count}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        )}
    </>
);



    return (
        <>
            <div style={{position:'fixed'}}>
            <svg className="wave" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#066c34de" fill-opacity="1" d="M0,224L24,197.3C48,171,96,117,144,106.7C192,96,240,128,288,117.3C336,107,384,53,432,69.3C480,85,528,171,576,192C624,213,672,171,720,154.7C768,139,816,149,864,176C912,203,960,245,1008,245.3C1056,245,1104,203,1152,192C1200,181,1248,203,1296,181.3C1344,160,1392,96,1416,64L1440,32L1440,0L1416,0C1392,0,1344,0,1296,0C1248,0,1200,0,1152,0C1104,0,1056,0,1008,0C960,0,912,0,864,0C816,0,768,0,720,0C672,0,624,0,576,0C528,0,480,0,432,0C384,0,336,0,288,0C240,0,192,0,144,0C96,0,48,0,24,0L0,0Z"></path></svg>
            <div className="mainlayout_cart_page">
                <Carousel_Demo demo_arr={suggest_product_arr}/>
                <p className="Heading" style={{color:'#246ac1'}}>Products !</p>
                    
                {products?.length > 0 && (
                    <div className="card_section" style={{width:'100%',display:'flex',flexDirection:'column'}}>
                    {products?.map((data)=>{
                      
                        return (
                            <div className="card_box" key={data.serial_number}>
                                <div className="img_div">
                                    <img alt='cart image img_card' className='card_img' src= {handle_image_Src(data.product_name)}/>
                                </div>
                                {console.log(data.product_name)}
                                <div className="content_div">
                                    <div className="text_div" >
                                        <span className="product_name" >{data.product_name}</span>
                                        <span className="product_price" >Rs.{data.price}</span>
                                        <span  className="product_desc" >{data.description}</span>
                                    </div>
                                   
                                    <div className="quantity_action_div" >
                                        <RiAddBoxFill className="quantity_increase"  onClick={() => handleAdd(data.serial_number)}/>
                                        <p className="quantity_text" >1</p>
                                        <TbSquareMinusFilled className="quantity_decrease" style={{}} onClick={() => handleMinus(data.serial_number)}/>
                                    </div>
                                </div>
                            </div>
                        )
                    })} 
                        

                       
                    </div> 
                )} 
                </div>
                <div className="footer" style={{paddingLeft:'1rem',zIndex:100}}> 
                        <div className="PriceContainer">
                            <p><span className="bold_text" onClick={handleCheckout}>Tax</span> : 5%</p>
                            <p><span className="bold_text">Total Price </span> : Rs {calculateTotalPrice()}</p>
                        </div>
                        <div className='checkoutButton' onClick={handleCheckout}>
                            Check Out
                            <ExpandCircleDownIcon className="arrow-circle"/>
                        </div>                   
                    </div>          
            </div>
        
           
        </>
    )
}

export default CartPage;