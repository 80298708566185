import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useContext} from 'react'
import { TrolleyContext } from "../../TrolleyContext";
import { v4 as uuidv4 } from 'uuid'
import { useNavigate} from 'react-router-dom'


import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import mqtt from 'mqtt';
import { useEffect } from 'react';

const defaultTheme = createTheme();

const UserInfo = () =>{

  const navigate = useNavigate()

    const {amount,updatedData} = useContext(TrolleyContext)
    const {setUpdatedData,setSubtotal,setAmount,device_id} = useContext(TrolleyContext)
    const [name, setName] = useState("");
    const [address,setAddress] = useState('');
    const [phonenumber,setPhonenumber] = useState('')
    


    const [resAlert, setresAlert] = useState(null)
    const [submitted, setSubmitted] = useState(false);
    const [client, setClient] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const [color_status,setColorstatus] = useState('success');

    // publisher topic
    const publisher_topic = `purchase/${device_id}/payment`;

    // input validation
    const [is_name_validity,setname_validity] = useState(false);
    const [is_phone_validity,setphone_validity] = useState(false);
    const [is_address_validity,setaddressvalidity] = useState(false);

    const handleSnackbarClose = () => {
      setSubmitted(false);
    };

    const handleSubmit = async(e) => {
        e.preventDefault();

        if (e.target.checkValidity() && is_name_validity && is_phone_validity && is_address_validity) {
            const userData = {
                name : name,
                address : address,
                phonenumber: phonenumber
            }
            console.log(userData)
            try {

              const totalAmount = amount;

                // const response = await fetch('http://localhost:3003/session/create-razorpay-order', {
                const response = await fetch('https://smart-trolley-api.quantanics.in/session/create-razorpay-order', {  
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body:JSON.stringify({
                    amount: totalAmount * 100, 
                    currency: 'INR', 
                  }),
                });
          
                const orderData = await response.json();

                console.log(orderData)
                console.log('orderData.amount',orderData.amount)
          
                const options = {
                  key: 'rzp_test_0MsloSAqW3knPj',
                  key_secret:'ePKlU8Hq8PUqSGHAvHNO0iir',
                  amount: orderData.amount,
                  currency: orderData.currency,
                  name: 'Your Product Name',
                  description: 'Payment for your product',
                  image: 'https://example.com/logo.png',
                  handler: async(response) => {
                    console.log('response')
                    console.log('Payment ID:', response.razorpay_payment_id);
                    setName('')
                    setAddress('')
                    setPhonenumber('')
                    // const result = await fetch('http://localhost:3003/session', {
                    const result = await fetch('https://smart-trolley-api.quantanics.in/session', {
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify({                      
                        status:"Payment Done",
                        payment_id: response.razorpay_payment_id,
                        session_id: uuidv4(),
                        user: userData,                  
                        products: updatedData.map((data,index)=>{
                          return {
                            product_name : data.product_name,
                            product_price : data.price
                          }
                        })
                      }),
                    });
                    console.log('result',result);
                    console.log("razorpay final result");
                    console.log(result);
                    
                    if(result.ok===true){
                      setUpdatedData([]);
                      setSubtotal(0);
                      setAmount(0);
                      let json_obj = {
                        "payment_status":"completed"
                      };
                      let pubmsg = JSON.stringify(json_obj);
                      publishMessage(pubmsg);
                      setSubmitted(true);
                      setColorstatus('success');
                      setresAlert("H/W and S/W Reseting...");
                      navigate('/');
                    }
                  },
                  prefill: {
                    name: userData.name, 
                    address: userData.address, 
                    contact: userData.phonenumber, 
                  },
                  notes: {
                    'order_id': orderData.id
                  },
                  theme: {
                    color: '#3399cc'
                  },
                };
          
                const razorpayInstance = new window.Razorpay(options);
          
                razorpayInstance.open();
          
              } catch (error) {
                console.error('Error creating Razorpay order:', error);
              }


        } else {
          alert("Form is invalid! Please check the fields...");
          setSubmitted(true);
          setColorstatus('warning');
          setresAlert("All Fields are Required!!");
        }
    };


    useEffect(()=>{
      if(updatedData.length>0){
         // Connect to the MQTT broker
        const options = {
            clientId: 'mqttjs_' + Math.random().toString(16).substr(2, 8),
            keepalive: 60,
            clean: true,
            username:'quantanics',
            password:'quantanics123'
        };

        const client = mqtt.connect('ws://quantanics.in:8083/mqtt', options);

        client.on('connect', () => {
            console.log('Connected to broker');
            setIsConnected(true);

            // Immediately publish data after connection is established
            let obj = {
              "payment_status":"progress"
            };
            let pub_msg_str = JSON.stringify(obj);
            client.publish(publisher_topic, pub_msg_str, (err) => {
              if (err) {
                console.error('Publish error: ', err);
              } else {
                console.log('Message published');
                setSubmitted(true);
                setColorstatus('success');
                setresAlert("Your hardware is on hold during payment");
              }
            });
        });

        client.on('error', (err) => {
            console.error('Connection error: ', err);
            client.end();
        });

        setClient(client);
        
    }
    },[updatedData])


    // Function to publish a message
    const publishMessage = (pub_msg) => {
      if (client && isConnected) {
        client.publish(publisher_topic, pub_msg, { qos: 1 }, (error) => {
            if (error) {
              console.error('Publish error: ', error);
            } else {
              setSubmitted(true);
              setColorstatus('success');
              setresAlert("Payment Work started!");
              console.log('Message sent: ', pub_msg);
            }
        });
      } else {
        console.error('Client not connected');
      }
    };

    // onblur function to validation checking
    const customer_name = (cname) =>{
      if(cname === ""){
        setname_validity(false);
        setSubmitted(true);
        setColorstatus('warning');
        setresAlert("Valid Field in Customer Name");
      }else{
        setname_validity(true);
      }
    }

    // onblur function to validation checking
    const phone_number_check = (phone_no)=>{
      const mobile_pattern = /^[6-9]\d{9}$/;
      if(mobile_pattern.test(phone_no)){
        setphone_validity(true);
      }else{
        setphone_validity(false);
        setSubmitted(true);
        setColorstatus('warning');
        setresAlert("Valid Field in Phone Number");
      }
    }

    //onblur function to validation checking
    const address_field_check = (address)=>{
      if(!/[A-Za-z0-9\s,.-]/.test(address)){
        setaddressvalidity(false);
        setSubmitted(true);
        setColorstatus('warning');
        setresAlert("Valid Field in Address");
      }else{
        setaddressvalidity(true);
      }
    }
  


    return (
        <>
            <svg className="wavy" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#066c34de" fill-opacity="1" d="M0,224L24,197.3C48,171,96,117,144,106.7C192,96,240,128,288,117.3C336,107,384,53,432,69.3C480,85,528,171,576,192C624,213,672,171,720,154.7C768,139,816,149,864,176C912,203,960,245,1008,245.3C1056,245,1104,203,1152,192C1200,181,1248,203,1296,181.3C1344,160,1392,96,1416,64L1440,32L1440,0L1416,0C1392,0,1344,0,1296,0C1248,0,1200,0,1152,0C1104,0,1056,0,1008,0C960,0,912,0,864,0C816,0,768,0,720,0C672,0,624,0,576,0C528,0,480,0,432,0C384,0,336,0,288,0C240,0,192,0,144,0C96,0,48,0,24,0L0,0Z"></path></svg>
            <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                >
                <Avatar sx={{ m: 1, bgcolor: '#68cf96de' }}>
                    <AccountCircleIcon sx={{scale:'1.2'}}/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Customer Details
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} style={{display:'flex',justifyContent:'center',flexDirection:'column',width:'100%',alignItems:'center'}}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Customer Name"
                        name="name"
                        autoComplete="name"
                        autoFocus
                        onChange={(e)=>setName(e.target.value)}
                        value={name}
                        onBlur={(e)=>customer_name(e.target.value)}
                    />
                    
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="phonenumber"
                        label="Phone Number"
                        type="string"
                        id="password"
                        autoComplete="phone-number"
                        value={phonenumber}
                        onChange={(e)=>setPhonenumber(e.target.value)}
                        onBlur={(e)=>phone_number_check(e.target.value)}

                    />
                    <TextField
                        margin="normal"
                        multiline
                        rows={2}
                        maxRows={4}
                        required
                        fullWidth
                        id="email"
                        label="Address"
                        name="address"
                        autoComplete="Address"
                        autoFocus
                        value={address}
                        onChange={(e)=>setAddress(e.target.value)}
                        onBlur={(e)=>address_field_check(e.target.value)}
                    />
                  
                    <Button
                    type="submit"
                    variant="contained"
                    sx={
                        { 
                            mt: 3,
                             mb: 2 ,
                             bgcolor:'#68cf96de',
                             color:'black',
                             fontWeight:'500',
                             fontSize:'18px',
                             borderRadius:'18px',
                             ':hover': {
                                bgcolor: '#066c34de',
                                color: 'white',
                            },
                        }
                    }
                    style={{
                        width:'200px', 
                    }}
                    >
                        Next
                    </Button>
                </Box>
                </Box>
            </Container>
            </ThemeProvider>

            <Snackbar open={submitted} autoHideDuration={3000} onClose={handleSnackbarClose} anchorOrigin={{vertical: 'bottom',horizontal: 'right',}}>
                <MuiAlert onClose={handleSnackbarClose} severity={color_status} sx={{ width: '100%' }}>
                    {resAlert}
                </MuiAlert>
            </Snackbar>
        </>
      );
}

export default UserInfo;