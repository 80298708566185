import React from "react";
import {Outlet} from 'react-router-dom'
import '../main_layout/main_layout.css'

const MainLayout = () => {
    return (
        <div className="">
            <Outlet/>
        </div>
    )
}

export default MainLayout;